import React, { ReactElement } from 'react';
import { AppProps, User, Device } from './types';
import { UUID } from './UUID';
import ApiServer from './ApiServer';
import { mobileVendor, osName, browserName } from 'react-device-detect';

class LocalProps extends AppProps {
	// isSubscribed: boolean;
	taps: string[] = [];
	removeOneTap: any;
}

interface LocalState {
	user: User;
	devices: Device[];
}

const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY || '');

export default class Settings extends React.Component<LocalProps, LocalState> {
	constructor(props: LocalProps, state: LocalState) {
		super(props, state);
		this.state = { user: new User(), devices: [] };
		this.promptAllow = this.promptAllow.bind(this);
		this.saveUser = this.saveUser.bind(this);
		this.saveDevice = this.saveDevice.bind(this);
		this.loadDevices = this.loadDevices.bind(this);
		this.displayDevices = this.displayDevices.bind(this);
		this.renderDeviceRow = this.renderDeviceRow.bind(this);
		this.displayTaps = this.displayTaps.bind(this);
	}

	componentDidMount() {
		const userId = localStorage.getItem(AppProps.CONST_USER_ID);
		if (userId) {
			// retrieve the user, then their devices
			ApiServer.get(`/user?userId=${userId}`).then((payload) => {
				const user = new User();
				Object.assign(user, payload);
				this.setState({ user: user }, () => {
					this.loadDevices(this.state.user.userId);
				});
			});
		}
	}

	loadDevices(userId?: string) {
		if (!userId) {
			return; // no user means no devices
		}
		ApiServer.get(`/device?userId=${userId}`).then((payload) => {
			const devices: Device[] = [];
			if (Array.isArray(payload)) {
				payload.forEach((ele: Object) => {
					const dev = Object.assign(new Device(), ele);
					devices.push(dev);
				});
				console.log(`loaded ${devices.length} devices for userId=${userId}`);
				this.setState({ devices: devices });
			} else {
				console.log(`no devices found for userId=${userId}`);
			}
		});
	}

	/**
	 * save the user to the back-end API
	 * @param {any} e submit button click event
	 * @param {string} name of the user
	 * @returns {void}
	 */
	async saveUser(e: any): Promise<void> {
		e.preventDefault();
		const form = e.target.form;
		const user = this.state.user;
		user.name = form.name.value;
		user.userId = user.userId || UUID.create();

		const resp = await ApiServer.post('/user', user);
		if (resp && resp.success === true) {
			localStorage.setItem(AppProps.CONST_USER_ID, user.userId);
			localStorage.setItem(AppProps.CONST_USER_NM, user.name);
			this.loadDevices(user.userId);
		}
	}

	/**
	 * save the device to the back-end API
	 * @param {number} o 1 if the device is to be notified, 0 if not
	 * @param {string} d for updates
	 * @param {PushSubscription} s the subscription - only present when adding 'this' device
	 * @returns {void}
	 */
	async saveDevice(o: number, d?: string | null, s?: PushSubscription | null): Promise<void> {
		const device = new Device();
		device.deviceId = d || UUID.create();
		device.userId = this.state.user.userId;
		// if we're saving a subscription we're saving 'this' device.  Confirm opt-in.
		device.optIn = s && Notification.permission === 'granted' ? 1 : o;
		if (s) {
			device.subscription = s;
			device.name = `${osName} ${
				mobileVendor === 'none' ? 'Desktop' : mobileVendor
			} ${browserName}`;
		}

		const resp = await ApiServer.post('/device', device);
		if (resp.success === true) {
			if (s) {
				// if the user just enrolled 'this' device, capture it's ID
				localStorage.setItem(AppProps.CONST_DEVICE_ID, device.deviceId); // capture this device's ID
			}
			this.loadDevices(this.state.user.userId);
		}
	}

	/**
	 * prompt the user to allow notifications
	 * @param {any} btnClick target event
	 * @returns {void}
	 */
	promptAllow(btnClick: any): void {
		btnClick.preventDefault();

		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.ready
				.then((registration) => {
					if (!registration.pushManager) {
						console.log('Push manager unavailable.');
						return;
					}

					const _this = this;
					registration.pushManager.getSubscription().then((existedSubscription) => {
						if (existedSubscription === null) {
							console.log('No subscription detected, make a request.');
							registration.pushManager
								.subscribe({
									applicationServerKey: convertedVapidKey,
									userVisibleOnly: true,
								})
								.then(function (newSubscription) {
									console.log('New subscription added.');
									_this.saveDevice(1, null, newSubscription);
								})
								.catch(function (e) {
									if (Notification.permission !== 'granted') {
										console.log('Permission was not granted.', e);
										const deviceId = localStorage.getItem(AppProps.CONST_DEVICE_ID) || null;
										_this.saveDevice(0, deviceId, null);
									} else {
										console.error('An error ocurred during the subscription process.', e);
									}
								});
						} else {
							console.log('Existing subscription detected.');
							const deviceId = localStorage.getItem(AppProps.CONST_DEVICE_ID) || null;
							_this.saveDevice(1, deviceId, existedSubscription);
						}
					});
				})
				.catch(function (e) {
					console.error('An error ocurred during Service Worker registration.', e);
				});
		}
	}

	render() {
		const userId = this.state.user.userId || localStorage.getItem(AppProps.CONST_USER_ID);
		const isAllowed = Notification.permission === 'granted';
		return (
			<div className='settings'>
				<form>
					<h3>My Name or Alias</h3>
					<input
						type='text'
						name='name'
						defaultValue={this.state.user.name}
						placeholder='My nickname is...'
					/>
					<button type='submit' onClick={this.saveUser}>
						{!userId ? 'Create Account' : 'Update'}
					</button>
					{this.state.user.userId && (
						<label>
							{userId && (
								<div>
									Enable notifications to receive them on this device:
									<br />
									<button onClick={this.promptAllow}>
										{isAllowed ? 'Turn off' : 'Allow'} Notifications to this device/browser
									</button>
								</div>
							)}
						</label>
					)}
					{this.displayDevices()}
					{this.displayTaps()}
				</form>
			</div>
		);
	}

	displayDevices() {
		const arr = this.state.devices;
		if (arr.length === 0) {
			return '';
		}

		return (
			<div className='deviceList'>
				<h3>My Devices</h3>
				<table className='devices'>
					<thead>
						<tr>
							<th>Device</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{arr.map((dev, idx) => {
							return this.renderDeviceRow(dev, idx);
						})}
					</tbody>
				</table>
			</div>
		);
	}

	renderDeviceRow(dev: Device, idx: number): ReactElement {
		return (
			<tr key={idx}>
				<td className='name'>{dev.name || dev.deviceId}</td>
				<td className='date'>
					{dev.subscription && dev.optIn ? 'Allowing Notifications' : 'Blocking Notifications'}
				</td>
			</tr>
		);
	}

	displayTaps() {
		return (
			<div className='oneTaps'>
				<h3>My One Taps</h3>
				<table>
					<tbody>
						{this.props.taps.map((t) => {
							return (
								<tr>
									<td>{t}</td>
									<td className='remove'>
										<a title='Remove this One Tap' onClick={(e) => this.props.removeOneTap(t)}>
											&times;
										</a>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<small>One Taps allow you to resend previous announcements with a single click.</small>
			</div>
		);
	}
}

/**
 * Google's method for turning a subscription into a payload using the provided public key
 * @param {string} base64String the public key from the server
 * @returns {uint8Array} generated int[]
 */
function urlBase64ToUint8Array(base64String: string) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	// eslint-disable-next-line
	const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}
