import React from 'react';
import { Message, AppProps } from './types';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';

interface LocalProps extends AppProps {
	msg: Message;
}

export default class MessageDisplay extends React.Component<LocalProps> {
	constructor(props: LocalProps) {
		super(props);
		this.state = { messages: [] };
	}

	render() {
		return (
			<tr className='message'>
				<td className='text'>{this.props.msg.text}</td>
				{/* <td className='group'>McKain Group</td> */}
				<td className='date'>
					<TimeAgo locale='en' datetime={new Date(this.props.msg.timestamp)} />
				</td>
			</tr>
		);
	}
}
