export class AppProps {
	static CONST_USER_ID = 'USER_ID';
	static CONST_DEVICE_ID = 'DEVICE_ID';
	static CONST_USER_NM = 'USER_NM';
	static CONST_ONE_TAPS = 'ONE_TAPS';
}
export interface Message {
	uuid: string;
	text: string;
	timestamp: number;
	groupId: string;
	senderName: string;
	deviceId: string;
}
export class MessageObj implements Message {
	uuid = '';
	text = '';
	timestamp = 0;
	groupId = '';
	senderName = '';
	deviceId = '';
}
export class User {
	userId: string = '';
	name: string = '';
	groups: string[] = [];
}
export class Device {
	deviceId: string = '';
	userId: string = '';
	name: string = '';
	subscription: PushSubscription | null = null;
	optIn: number = 0; // 0=N, 1=Y
}
export class Group {
	groupId: string = '';
	name: string = '';
}
export class UserGroup {
	// the XR between user and group
	userGroupId: string = '';
	groupId: string = '';
	userId: string = '';
	optIn: number = 0;
}
