export default class ApiServer {
	/**
	 * Reusable Http POST for our API server target
	 * @param {string} url to call, incl querystring as needed
	 * @param {Object} payload request body
	 * @returns {Promise} the promise awaiting an http response
	 */
	static post(url: string, payload: Object): Promise<any> {
		return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
			method: 'POST',
			body: JSON.stringify(payload),
			headers: {
				'Content-Type': 'application/json',
				'X-API-Key': `${process.env.REACT_APP_AWS_API_KEY}`,
			},
		})
			.then((resp) => resp.json())
			.catch((err) => console.error(err));
	}

	/**
	 * Reusable Http GET for our API server target
	 * @param {string} url to call, incl querystring as needed
	 * @returns {Promise} the promise awaiting an http response
	 */
	static get(url: string): Promise<any> {
		return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'X-API-Key': `${process.env.REACT_APP_AWS_API_KEY}`,
			},
		})
			.then((resp) => resp.json())
			.catch((err) => console.error(err));
	}
}
