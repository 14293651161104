import React from 'react';
import { AppProps } from './types';

class LocalProps extends AppProps {
	sendMessage: any;
}

export default class NotifyForm extends React.Component<LocalProps> {
	constructor(props: LocalProps) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(e: any) {
		e.preventDefault();
		const form = e.target;
		if (form.message.value.length > 0) {
			this.props.sendMessage(form.message.value, form.oneTap.checked === true);
			form.reset();
		}
		return false;
	}

	render() {
		return (
			<form className='inputForm' id='form' onSubmit={this.handleSubmit}>
				<label htmlFor='input'>
					What's the word? <small>&nbsp;(max 150 chars)</small>
				</label>
				<input
					id='input'
					type='text'
					className='announcement'
					required={true}
					name='message'
					maxLength={150}
					placeholder='Enter your announcement here'
				/>
				<label className='checkbox'>
					<input type='checkbox' name='oneTap' value='1' defaultChecked={true} /> Save as One Tap?
				</label>
				<button type='submit'>Send</button>
			</form>
		);
	}
}
