/* eslint no-unused-vars: "off" */
import React from 'react';
import OneTapDisplay from './OneTapDisplay';
import NotifyForm from './NotifyForm';
import MessageList from './MessageList';
import { MessageObj, AppProps } from './types';
import MessageStore from './MessageStore';
import Settings from './Settings';
import { UUID } from './UUID';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ApiServer from './ApiServer';

// Initialize the Amazon Cognito credentials provider
// AWS.config.region = 'us-east-1'; // Region
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: 'us-east-1:ecd9ac2c-a582-4d0f-ae36-cfdadd9c6d3f',
// });
// Firebase appid: let-me-know-354e6

interface LocalState {
	rand: string;
	isOnline: boolean;
	isSubscribed: boolean;
	userId: string;
	taps: string[];
}

class App extends React.Component<AppProps, LocalState> {
	constructor(props: AppProps, state: LocalState) {
		super(props, state);
		const userId = localStorage.getItem(AppProps.CONST_USER_ID) || '';
		this.state = {
			rand: '',
			isOnline: true,
			userId: userId,
			isSubscribed: userId !== '' && Notification.permission === 'granted',
			taps: JSON.parse(localStorage.getItem(AppProps.CONST_ONE_TAPS) || '[]'),
		};
		this.sendMessage = this.sendMessage.bind(this);
		this.addOneTap = this.addOneTap.bind(this);
		this.removeOneTap = this.removeOneTap.bind(this);
	}

	sendMessage(message: string, isOneTap: boolean) {
		console.log(`rcvd message: ${message}`);
		const msg = new MessageObj();
		msg.text = message;
		msg.timestamp = new Date().getTime();
		msg.uuid = UUID.create();
		msg.groupId = '1';
		msg.senderName = localStorage.getItem(AppProps.CONST_USER_NM) || '';
		msg.deviceId = localStorage.getItem(AppProps.CONST_DEVICE_ID) || '';
		ApiServer.post('/notification', msg).then((resp) => {
			MessageStore.store(msg);
			if (isOneTap) {
				this.addOneTap(msg);
			} else {
				this.setState({ rand: msg.uuid }); // this just triggers a redraw
			}
		});
	}

	addOneTap(msg: MessageObj) {
		const taps = (this.state.taps || []).filter((t) => t !== msg.text);
		taps.push(msg.text);
		localStorage.setItem(AppProps.CONST_ONE_TAPS, JSON.stringify(taps));
		this.setState({ rand: msg.uuid, taps: taps });
	}

	removeOneTap(msg: string) {
		const taps = (this.state.taps || []).filter((t) => t !== msg);
		localStorage.setItem(AppProps.CONST_ONE_TAPS, JSON.stringify(taps));
		this.setState({ rand: msg, taps: taps });
	}

	render() {
		return (
			<div className='App'>
				<header className='App-header'>
					<img src='/logo192.png' alt='Let Them Know' />
					<h1>Let Them Know&hellip;</h1>
					<p>Broadcast real-time messsages to keep others informed of your status.</p>
				</header>
				{this.state.isSubscribed && (
					<OneTapDisplay sendMessage={this.sendMessage} taps={this.state.taps} />
				)}
				{this.state.isSubscribed && <NotifyForm sendMessage={this.sendMessage} />}

				<br />
				<br />
				<Tabs defaultIndex={this.state.isSubscribed ? 0 : 2}>
					<TabList>
						<Tab disabled={!this.state.isSubscribed}>Sent</Tab>
						<Tab disabled={!this.state.isSubscribed}>Received</Tab>
						<Tab>Settings</Tab>
					</TabList>

					<TabPanel>
						<MessageList key={this.state.rand} type={MessageStore.TYPE.SENT} />
					</TabPanel>
					<TabPanel>
						<MessageList key={this.state.rand} type={MessageStore.TYPE.RECIEVED} />
					</TabPanel>
					<TabPanel>
						<Settings taps={this.state.taps} removeOneTap={this.removeOneTap} />
					</TabPanel>
				</Tabs>

				<footer>Version {process.env.REACT_APP_VERSION}</footer>
			</div>
		);
	}
}

export default App;
