/* eslint no-unused-vars: "off" */
import React from 'react';
import MessageDisplay from './MessageDisplay';
import { Message, AppProps } from './types';
import MessageStore from './MessageStore';

interface LocalProps extends AppProps {
	type?: string;
}

interface LocalState {
	messages: Message[];
}

export default class MessageList extends React.Component<LocalProps, LocalState> {
	constructor(props: LocalProps, state: LocalState) {
		super(props, state);
		this.state = { messages: [] };
	}

	componentDidMount() {
		this.setState({ messages: MessageStore.load(this.props.type) || [] });
	}

	render() {
		if (this.state.messages.length === 0) {
			return (
				<div>
					<p>There's nothing to see here, yet!</p>
				</div>
			);
		}

		return (
			<div>
				<table className='messages'>
					<thead>
						<tr>
							<th>Message</th>
							{/* <th>Recipients</th> */}
							<th>Sent</th>
						</tr>
					</thead>
					<tbody>
						{this.state.messages.map((m, idx) => {
							return <MessageDisplay key={idx} msg={m} />;
						})}
					</tbody>
				</table>
				<p className='footnote'>
					Only the {MessageStore.MAX_LENGTH} most recent messages are stored.
					<br />
					Only messages sent from this device are listed.
				</p>
			</div>
		);
	}
}
