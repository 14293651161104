import { AppProps } from './types';
import React from 'react';

class LocalProps extends AppProps {
	taps: string[] = [];
	sendMessage: any;
}

export default class OneTapDisplay extends React.Component<LocalProps> {
	constructor(props: LocalProps) {
		super(props);
	}

	render() {
		const taps = this.props.taps;
		if (!taps || taps.length === 0) {
			return '';
		}

		return (
			<div className='one-taps'>
				{taps.map((msg) => {
					return <button onClick={(e) => this.props.sendMessage(msg)}>{msg}</button>;
				})}
			</div>
		);
	}
}
